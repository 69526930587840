import React from "react"
import SEO from "../components/SEO"
import Template from "../components/Template"
import HeaderPropiedad from "../components/Properties/HeaderPropiedad"
import InfoPropiedad from "../components/Properties/InfoPropiedad"
import ContactPropiedad from "../components/Properties/ContactPropiedad"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import noImage from "../styles/images/no-image-house.png"
import CarrouselPropiedad from "../components/Properties/CarrouselPropiedad"


import {graphql} from 'gatsby'

const PropiedadTemplate = props =>{
    const propiedad= props.data.propiedad
    return (
        <Template>
          <SEO title={propiedad.title} description={propiedad.descripcion}/>
          <Container>
              <Row className="justify-content-center">
                  <Col xs={12} className="main">
                    <HeaderPropiedad 
                        tipoNegocio={propiedad.modalidad} 
                        titulo={propiedad.title}
                        slug={propiedad.slug.current}
                        precio={propiedad.precio}
                        unidad={propiedad.unidad}
                        fechaPublicacion={propiedad.fechaPublicacion}
                        zona={propiedad.zona}
                        ciudad={propiedad.ciudad}
                        region={propiedad.region}
                        modalidad={propiedad.modalidad} />
                  </Col>
                  <Col md={9} style={{marginTop:"1em"}}>
                    <CarrouselPropiedad image={propiedad.image? propiedad.image.asset.url+"?w=600" : noImage} gallery={propiedad.gallery}/>
                  </Col>
                  <Col  lg={{span: 3, order:0}} md={{span: 3,order:0}} sm={{order:"last"}} xs={{order:"last"}} style={{marginTop:"1em"}} >
                  <ContactPropiedad propiedad={propiedad}/>
                  </Col>
                  <Col xs={12}>

                    <InfoPropiedad propiedad={propiedad}/>
                   
                  </Col>
              </Row>
          </Container>
            
        </Template>
      )
}

export default PropiedadTemplate

export const query = graphql`
  query PropertyTemplateQuery($id: String!) {
    propiedad: sanityPropiedad(id: {eq: $id}) {
      _id
      title
      unidad
      precio
      fechaPublicacion
      zona
      ciudad
      region
      modalidad
      slug{
          current
      }
      _rawDescripcion
      image{
        asset{
          url
        }
      }
      gallery{
        asset{
          url
        }
      }
      banos
      caracteristicasExternas
      caracteristicasInternas
      codigo
      dormitorios
      estado
      estrato
      fechaPublicacion
      pisos
      superficieConstruccion
      superficieTerreno
      superficiePrivada
      year
      tipo {
        name
      }
      estacionamientos
      geopoint{
        lat
        lng
      }
      video
      asesor {
        email
      	image{
        	asset{
          	url
        	}
      	}
      	name
      	telefonoFijo
      	telefonoMovil
    	}
    }
  }
`