import React from "react"
import { Map, TileLayer, Marker, Popup} from 'react-leaflet'

const LeafletMap=(props) =>{
    return (
        <Map center={props.position} zoom={props.zoom} style={{height:"50vh"}}>
          <TileLayer
            url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
            attribution="&copy; OpenStreetMap contributors"
          />
          <Marker position={props.position}>
            <Popup>Ubicación aproximada</Popup>
          </Marker>
        </Map>
    )
}

export default LeafletMap