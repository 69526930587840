import React from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import noImage from "../../styles/images/no-image-asesor.png"
import { FaUserTie } from "@react-icons/all-files/fa/FaUserTie";
import {HiOutlineMail} from "@react-icons/all-files/hi/HiOutlineMail";
import {FaMobileAlt} from  "@react-icons/all-files/fa/FaMobileAlt";
import {BiPhone} from "@react-icons/all-files/bi/BiPhone"
import {FaWhatsapp} from "@react-icons/all-files/fa/FaWhatsapp"
import {FaRegPaperPlane} from "@react-icons/all-files/fa/FaRegPaperPlane"
import Alert from "react-bootstrap/Alert"

import axios from 'axios';


const ContactPropiedad=(props) =>{
    const [form, setForm] = React.useState({
        nombre:"",
        telefono:"",
        correo:"",
        mensaje: ""
    })
  
    const handleChange=(e)=>{
        const valor=e.target.value
        const campo=e.target.name
        setForm(prevState=>{
            return {
                ...prevState,
                [campo]: valor
            }
        })
    }
    const handleSubmit=(e)=>{
        e.preventDefault()
        document.getElementById("submitContactoAsesor").disabled="true"
     
       axios.post(`/.netlify/functions/send-email-asesor`, { 
            message: form.mensaje,
            contactName: form.nombre,
            contactEmail: form.correo,
            contactNumber: form.telefono,
            identificador: props.propiedad._id
        }).then(res=>{
            if (typeof window !== 'undefined'){
                window.alert("Mensaje enviado")
            }
                document.getElementById("submitContactoAsesor").removeAttribute('disabled')
        })
    }
    const asesor=props.propiedad.asesor
    const numero=asesor.telefonoMovil;
    const handleWhatsApp=()=>{
        let noSpaces=numero.replaceAll(/ /g,'')
        let last9=noSpaces.slice(-9)
        const url=`https://api.whatsapp.com/send?phone=+56${last9}&text=Quiero%20consultar%20acerca%20de%20una%20propiedad`
        if (typeof window !== 'undefined') {
            var win = window.open(url, '_blank');
            win.focus();
        }
        
    }
    return(
        <div style={{paddingTop: "2em", textAlign: "center", backgroundColor: "var(--primary)",color:"var(--brightText)", paddingLeft:"1em", paddingRight:"1em", paddingBottom: "1em"}}>
               <img  className="imagenAsesor" src={asesor.image? asesor.image.asset.url+"?w=100" : noImage} alt={"Fotografía del asesor "+asesor.name}/>
                    <span className="labelFicha labelDatosAsesor" style={{display:"block-content", marginTop:"1em"}}>{asesor.name}</span>
                    <span className="labelDatosAsesor" style={{display:"block-content"}}>Asesor inmobiliario</span>
                   
                <Form className="formContactoAsesor" onSubmit={handleSubmit}>
                  
                        { asesor.telefonoMovil && (<Button type="button" id="buttonWhatsApp" variant="success" style={{display:"block-content", marginTop:"1em", marginBottom:"0.5em"}} onClick={handleWhatsApp} ><FaWhatsapp /> Contáctame</Button>)}
                   
                        <Form.Control size="sm" className="controlFormularioAsesor" type="text" name="nombre" value={form.nombre} onChange={handleChange} required placeholder="Su nombre"/>
                  
                        <Form.Control size="sm" className="controlFormularioAsesor" type="email" name="correo" value={form.correo} onChange={handleChange} required placeholder="Correo electrónico" />
              
                        <Form.Control size="sm" className="controlFormularioAsesor" type="number" name="telefono" value={form.telefono} onChange={handleChange} required placeholder="Teléfono" />
              
                        <Form.Control size="sm" className="controlFormularioAsesor" as="textarea" name="mensaje" value={form.mensaje} onChange={handleChange} required rows={2} placeholder="Me interesa esta propiedad. ¡Quiero saber más!" />
                    
                     <Button type="submit" id="submitContactoAsesor" variant="primary" style={{backgroundColor:"var(--secondary)", color: "var(--darkText)", border: 0, marginTop:"0.5em"}}><FaRegPaperPlane /> Enviar</Button>
              
                    </Form>
        </div>
       
    )
}

export default ContactPropiedad