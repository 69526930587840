import React from "react"
import Button from "react-bootstrap/Button"
import ReadMoreReact from 'read-more-react';
const BlockContent = require('@sanity/block-content-to-react')

const DescriptorPropiedad=(props) =>{
    return(
        <div className="descriptionContainer">
            <BlockContent blocks={props.descripcion}  />
       </div>
    )
}

export default DescriptorPropiedad