import React from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import LeafletMap from "./LeafletMap"

const MapaPropiedad=(props) =>{
    const {lat, lng}=props.propiedad.geopoint
    if (typeof window !== 'undefined') {
        return (
            <LeafletMap position={[lat,lng]} zoom={13}/>
        )
    }else{
        return(<span></span>)
    }
}

export default MapaPropiedad