import React from "react"
import { FaMapMarkerAlt } from "@react-icons/all-files/fa/FaMapMarkerAlt"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import formatearDinero from "../../utils/Money"
import useSiteMetadata from '../../hooks/useSiteMetadata';
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    WhatsappIcon,
    EmailIcon
  } from "react-share";

  const rrssStyle={
      marginLeft: "2px"
  }
const HeaderPropiedad=(props) =>{
    const { siteURL } = useSiteMetadata();
    const url=`${siteURL}/propiedad/${props.slug}`
    return(
        <Row>
            <Col md={9}>
                <h4>{props.modalidad=="Venta"? "Se Vende": "Se Arrienda"}</h4>
                <h3 className="tituloPropiedad">{props.titulo}</h3>
                <span><FaMapMarkerAlt /> {`${props.zona? props.zona+", ": ""}${props.ciudad}, ${props.region}`}</span>

            </Col>
            <Col md={3} className="precioContainer">
                <h4>Precio</h4>
                <h3 className="tituloPropiedad">{`${props.unidad==="UF"? "UF " : "$"}${formatearDinero(props.precio)} ${props.modalidad!=="Venta"? " al mes" : ""}`}</h3>
                <span>
                    <span><FacebookShareButton url={url}><FacebookIcon style={rrssStyle} round={true} size={32}/></FacebookShareButton></span>
                    <span><WhatsappShareButton url={url}><WhatsappIcon style={rrssStyle} round={true} size={32} /></WhatsappShareButton></span>
                    <span><TwitterShareButton url={url}><TwitterIcon style={rrssStyle} round={true} size={32} /></TwitterShareButton></span>
                    <span><LinkedinShareButton url={url}><LinkedinIcon style={rrssStyle} round={true} size={32} /></LinkedinShareButton></span>
                    <span><EmailShareButton url={url}><EmailIcon style={rrssStyle} round={true} size={32} /></EmailShareButton></span>
                    
                </span>

            </Col>
        </Row>
    )
}

export default HeaderPropiedad