import React from "react"
import Carousel from "react-bootstrap/Carousel"

const CarrouselPropiedad=(props) =>{
    return(
        <div>
            
            <Carousel>
                <Carousel.Item interval={1000000}>
                    <img src={props.image} alt="Imagen principal de la propiedad" className="responsive propertyImageFull" />
                </Carousel.Item>
                {props.gallery.map(picture => (
                <Carousel.Item interval={1000000}>
                   <img src={picture.asset.url+"?w=600"} alt="Imagen de la galería" className="responsive propertyImageFull" />
                </Carousel.Item>
                ))}
                
                </Carousel>
        </div>
    )
}

export default CarrouselPropiedad