import React from "react"
import YouTube from "react-youtube"



function matchYoutubeUrl(url){
    var p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
     return (url.match(p)) ? RegExp.$1 : false ;
}

const VideoPropiedad=(props) =>{
    const videoId=matchYoutubeUrl(props.video)
    return(
        <YouTube videoId={videoId} onReady={onReady} />
    )
}

const onReady=(event)=>{
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }
export default VideoPropiedad