import React from "react"
import FichaPropiedad from "./FichaPropiedad"
import MapaPropiedad from "./MapaPropiedad"
import VideoPropiedad from "./VideoPropiedad"
import Accordion from "react-bootstrap/Accordion"
import Card from "react-bootstrap/Card"
import Button from "react-bootstrap/Button"
import DescriptorPropiedad from "./DescriptorPropiedad"
import QuickInfoPropiedad from "./QuickInfoPropiedad"
import { BiHome } from "@react-icons/all-files/bi/BiHome";
import {BsMap} from "@react-icons/all-files/bs/BsMap";
import {FiYoutube} from "@react-icons/all-files/fi/FiYoutube";
import {BiSpreadsheet} from "@react-icons/all-files/bi/BiSpreadsheet";



const cardHeaderStyle={
    backgroundColor: "var(--secondary)",
}
const labelStyle={
    color: "var(--darkText)"
}
const cardStyle={
    marginTop: "1em"
}
const handleClick=()=>{
    window.dispatchEvent(new Event('resize'))
}

const InfoPropiedad=(props) =>{
    return(
        <div style={{
            marginTop: "2em"
        }}>
            <Accordion defaultActiveKey="10">
                <Card style={cardStyle}>
                    <Card.Header style={cardHeaderStyle}>
                        <Accordion.Toggle as={Button} variant="link" eventKey="10" style={labelStyle}><BiHome /> Descripción</Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="10">
                        <Card.Body>
                        <DescriptorPropiedad  descripcion={props.propiedad._rawDescripcion}/>
                        <QuickInfoPropiedad
                            superficieConstruccion={props.propiedad.superficieConstruccion} 
                            superficieTerreno={props.propiedad.superficieTerreno} 
                            banos={props.propiedad.banos} 
                            dormitorios={props.propiedad.dormitorios} />
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card style={cardStyle}>
                    <Card.Header style={cardHeaderStyle}>
                        <Accordion.Toggle as={Button} variant="link" eventKey="0" style={labelStyle}><BiSpreadsheet /> Ficha propiedad</Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body><FichaPropiedad propiedad={props.propiedad}/></Card.Body>
                    </Accordion.Collapse>
                </Card>
                {props.propiedad.geopoint && (<Card style={cardStyle}>
                    <Card.Header style={cardHeaderStyle}>
                        <Accordion.Toggle as={Button} variant="link" eventKey="1" style={labelStyle} onClick={handleClick}><BsMap /> Mapa</Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body> <MapaPropiedad propiedad={props.propiedad}/></Card.Body>
                    </Accordion.Collapse>
                </Card>)}
                {props.propiedad.video && (<Card style={cardStyle}>
                    <Card.Header style={cardHeaderStyle}>
                        <Accordion.Toggle as={Button} variant="link" eventKey="3" style={labelStyle}><FiYoutube /> Video</Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="3">
                        <Card.Body> <VideoPropiedad video={props.propiedad.video}/></Card.Body>
                    </Accordion.Collapse>
                </Card>)}
                
            
            </Accordion>
            
            
            
          
        </div>
    )
}

export default InfoPropiedad