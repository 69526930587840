import React from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import formatDate from "../../utils/Date"
import {GrStatusGood} from "@react-icons/all-files/gr/GrStatusGood";

const FichaPropiedad=(props) =>{
    const propiedad= props.propiedad
    return(
        <div>
            <Row>
                <Col xs={12}>
                    <h5>Información de la propiedad</h5>
                    <Row className="ficha">
                        {propiedad.codigo && (<Col sm={6}><span className="labelFicha">Código</span>: {propiedad.codigo}</Col>)}
                        {propiedad.fechaPublicacion && (<Col sm={6}><span className="labelFicha">Fecha de publicación</span>: {formatDate(propiedad.fechaPublicacion)}</Col>)}
                        {propiedad.tipo && (<Col sm={6}><span className="labelFicha">Tipo</span>: {propiedad.tipo.name}</Col>)}
                        {propiedad.estado && (<Col sm={6}><span className="labelFicha">Estado</span>: {propiedad.estado}</Col>)}
                        {propiedad.pisos && (<Col sm={6}><span className="labelFicha">Pisos</span>: {propiedad.pisos}</Col>)}
                        {propiedad.dormitorios && (<Col sm={6}><span className="labelFicha">Dormitorios</span>: {propiedad.dormitorios}</Col>)}
                        {propiedad.banos && (<Col sm={6}><span className="labelFicha">Baños</span>: {propiedad.banos}</Col>)}
                        {propiedad.estrato && (<Col sm={6}><span className="labelFicha">Estrato</span>: {propiedad.estrato}</Col>)}
                        {propiedad.year && (<Col sm={6}><span className="labelFicha">Año construcción</span>: {propiedad.year}</Col>)}
                        {propiedad.superficieConstruccion && (<Col sm={6}><span className="labelFicha">Superficie construida</span>: {propiedad.superficieConstruccion} M<sup>2</sup></Col>)}
                        {propiedad.superficieTerreno && (<Col sm={6}><span className="labelFicha">Superficie terreno</span>: {propiedad.superficieTerreno} M<sup>2</sup></Col>)}
                        {propiedad.superficiePrivada && (<Col sm={6}><span className="labelFicha">Superficie privada</span>: {propiedad.superficiePrivada} M<sup>2</sup></Col>)}
                        {propiedad.estacionamientos && (<Col sm={6}><span className="labelFicha">Estacionamientos</span>: {propiedad.estacionamientos}</Col>)}
                    </Row>
                </Col>
                {propiedad.caracteristicasInternas && propiedad.caracteristicasInternas.length>0 && (
                    <Col xs={12} style={{marginTop: "1em"}}>
                        <h5>Características internas</h5>
                        <Row>
                            {propiedad.caracteristicasInternas.map(caracteristica =>(
                                <Col sm={3}><span className="labelFicha"><GrStatusGood /> {caracteristica}</span></Col>
                            ))}
                        </Row>
                    </Col>
                )}

                {propiedad.caracteristicasExternas && propiedad.caracteristicasExternas.length>0 && (
                    <Col xs={12} style={{marginTop: "1em"}}>
                        <h5>Características externas</h5>
                        <Row>
                            {propiedad.caracteristicasExternas.map(caracteristica =>(
                                <Col sm={3}><span className="labelFicha"><GrStatusGood /> {caracteristica}</span></Col>
                            ))}
                        </Row>
                    </Col>
                )}
                
            </Row>
        </div>
    )
}

export default FichaPropiedad